import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Thimbles from '../Component/Thimbles'
import DiceGame from '../Component/DiceGame';
import Rockpaper from '../Component/Rockpaper'
import ThreeRoulette from '../Component/ThreeRoulette'
import Sidebars from "../Component/Sidebars";

import StyleComp from "../Component/StyleComp";

function Routers() {
  return (
    <Router  >
        <Routes >
            <Route exact path="/Thimbles" element={<Thimbles/>}/>
            <Route exact path="/dicegame" element={<DiceGame/>}/>
            <Route exact path="/Rockpaper" element={<Rockpaper/>}/>
             <Route exact path="/Sidebars" element={<Sidebars/>}/>
             <Route exact path="/ThreeRoulette" element={<ThreeRoulette/>}/> 
             <Route exact path='/StyleComp' element={<StyleComp/>}/>
           
             
        </Routes>
    </Router>
  )
}

export default Routers;






