import React, { useEffect, useState } from "react";
import useContractService from "../Services/useContractService";
import { toast } from "react-toastify";
import Header from "./Header";
import { Errorhandler } from "../Error/ErrorHandler";
import { useAccount } from "wagmi";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Web3 from "web3";
import Slide from "@mui/material/Slide";
import Backsound from "./Backsound";
function Rockpaper() {
  const { address } = useAccount();
  const {
    playRockPaper,
    getMaxMinBet,
    getBalance,
    withdrawEarnings,
    betId,
    getResultHistory,
  } = useContractService();
  const Bet = [0.25,0.5, 1, 2, 5, 10];
  const [BetPtr, setBetPtr] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isWin, setisWin] = useState(null);
  const [balance, setBalance] = useState(0);
  const [playAgain, setPlayAgain] = useState(false);
  const [choices, setChoices] = useState({
    contractChoice: null,
    userChoice: null,
  });
  const [open, setOpen] = React.useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [playerChoice, setPlayerChoice] = useState(null);
  const [history, setHistory] = useState(null);
  const [historyLoader, setHistoryLoader] = useState(false);
  const [withdrawLoader, setWithdrawLoader] = useState(false);
  const DecrementBet = () => {
    if (BetPtr === 0) return;
    setBetPtr(BetPtr - 1);
  };
  const IncrementBet = () => {
    if (BetPtr === Bet.length - 1) return;
    setBetPtr(BetPtr + 1);
  };
  const playGame = async () => {
    let choice = playerChoice;
    try {
      if (choice === null) {
        toast.error("Please select your choice");
      } else {
        const { result, contractChoice, userChoice } = await playRockPaper(
          Bet[BetPtr],
          choice,
          setLoader
        );
        setLoader(false);
        setChoices({ userChoice: userChoice, contractChoice: contractChoice });
        if (result == 1) {
          setisWin("win");
        } else if (result == 0) {
          setisWin("loose");
        } else {
          setisWin("tie");
        }
      }
    } catch (error) {
      Errorhandler(error);
      setLoader(false);
      throw error;
    }
  };
  const HandleResultClose = () => {
    setisWin(null);
    setPlayAgain(true);
  };
  const getMaxBet = async () => {
    try {
      const result = await getMaxMinBet();
    } catch (error) {
      throw error;
    }
  };
  const getBalances = async () => {
    try {
      const result = await getBalance(address);
      setBalance(result);
    } catch (error) {
      throw error;
    }
  };
  const withDrawEarning = async (e) => {
    e.preventDefault();
    try {
      setWithdrawLoader(true);
      const result = await withdrawEarnings();
      setWithdrawLoader(false);
      toast.success("Your amount withdraw successfully!");
    } catch (error) {
      setWithdrawLoader(false);
      toast.error(error);
      throw error;
    }
  };

  const web3 = new Web3(Web3.givenProvider);
  const resultHistory = async () => {
    try {
      let id = await betId();
      setHistoryLoader(true);
      if (id) {
        const resultData = [];
        let count = 10;
        while (id > 0 && count > 0) {
          const res = await getResultHistory(id);
          let playerChoice = res.pickedChoice;
          let contractsChoice = res.contractsChoice;
          let result = res.result.toNumber();
          let profit = web3.utils.fromWei(res.profit._hex, "ether");
          let betAmt = web3.utils.fromWei(res.amount._hex, "ether");
          resultData.push({
            playerChoice,
            contractsChoice,
            result,
            profit,
            betAmt,
          });
          id--;
          count--;
        }
        setHistoryLoader(false);
        setHistory(resultData);
      }
    } catch (error) {
      setHistoryLoader(false);
      throw error;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getMaxBet();
    getBalances();
    resultHistory();
  }, [balance, isWin]);

  return (
    <>
      {loader ? <div className={loader}></div> : null}
      {isWin == "win" ? (
        <div className="win" onClick={() => HandleResultClose()}></div>
      ) : isWin == "loose" ? (
        <div className="lose" onClick={() => HandleResultClose()}>
          <img src="/react/assets/img/youlose.png"></img>
        </div>
      ) : isWin == "tie" ? (
        <div className="tie" onClick={() => HandleResultClose()}>
          <img src="/react/assets/img/you tied!.png"></img>
        </div>
      ) : (
        ""
      )}
      <Header />
      <div className="wrapper wow animated" style={{ visibility: "visible" }}>
        <div
          id="big-text"
          className="wow bounceInDown "
          data-wow-delay="4s"
          style={{
            visibility: "visible",
            animationDelay: "4s",
            animationName: "bounceInDown",
          }}
        ></div>
        <div
          id="texture"
          className="bg-image"
          data-bg-image="images/texture.png"
          style={{
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: " 788px",
          }}
        ></div>
        <div id="clouds" className=""></div>
        <div
          id="hole"
          className="wow bounceInUp"
          data-wow-delay="3s"
          style={{
            visibility: "visible",
            animationDelay: "3s",
            animationName: "bounceInUp",
          }}
        ></div>
        <div
          id="moon"
          className="wow bounceInUp"
          data-wow-delay="2s"
          style={{
            visibility: "visible",
            animationDelay: "2s",
            animationName: "bounceInUp",
          }}
        ></div>
        <div
          id="hand"
          className="wow bounceInUp down"
          data-wow-delay="3.5s"
          style={{
            visibility: "visible",
            animationDelay: "3.5s",
            animationName: "bounceInUp",
          }}
        ></div>
        <div
          id="grass"
          className="wow bounceInUp"
          data-wow-delay="1s"
          style={{
            top: " 605px",
            left: " -107.429px",
            visibility: "visible",
            animationDelay: " 1s",
            animationName: " bounceInUp",
          }}
        ></div>
        <div
          id="grass2"
          className="wow bounceInUp"
          data-wow-delay="1.5s"
          style={{
            top: " 625px",
            left: "  -135.141px",
            visibility: "visible",
            animationDelay: " 1.5s",
            animationName: " bounceInUp",
          }}
        ></div>
      </div>
      <section className="RockPaper">
        <h2 className="winnermassege"></h2>
        <div className="container-fluid" style={{ maxWidth: "1640px" }}>
          <div className="row">
            <div className=" col-12 col-sm-12 col-md-12 col-lg-8 ">
              <form className="Rockform">
                <img src="./react/assets/img/2.png" width={"20%"}></img>
                <input
                  type="radio"
                  id="rock-rock"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 0 && choices.userChoice === 0
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="rock-paper"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 0 && choices.userChoice === 1
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="rock-scissors"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 0 && choices.userChoice === 2
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="paper-rock"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 1 && choices.userChoice === 0
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="paper-paper"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 1 && choices.userChoice === 1
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="paper-scissors"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 1 && choices.userChoice === 2
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="scissors-rock"
                  name="rock-paper-scissors"
                  value="true"
                />
                <input
                  type="radio"
                  id="scissors-paper"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 2 && choices.userChoice === 1
                      ? true
                      : false
                  }
                />
                <input
                  type="radio"
                  id="scissors-scissors"
                  name="rock-paper-scissors"
                  checked={
                    choices.contractChoice === 2 && choices.userChoice === 2
                      ? true
                      : false
                  }
                />
                <div className="w-100">
                  <div></div>
                  <div id="hands">
                    <div className="Twohands">
                      <div>
                        <div className="choicehand">Computer</div>
                        <div className="hand" id="computer-hand">
                          <div className="fist"></div>
                          <div className="finger finger-1"></div>
                          <div className="finger finger-2"></div>
                          <div className="finger finger-3"></div>
                          <div className="finger finger-4"></div>
                          <div className="thumb"></div>
                          <div className="arm"></div>
                        </div>
                      </div>

                      <div>
                        <div className="choicehand">User</div>
                        <div className="hand" id="user-hand">
                          <div className="fist"></div>
                          <div className="finger finger-1"></div>
                          <div className="finger finger-2"></div>
                          <div className="finger finger-3"></div>
                          <div className="finger finger-4"></div>
                          <div className="thumb"></div>
                          <div className="arm"></div>
                        </div>
                      </div>
                    </div>
                    <div id="icons">
                      <div
                        onClick={() => setPlayerChoice(0)}
                        className={`${playerChoice == 0 ? "selecteditem" : ""}`}
                      >
                        <label for="rock-rock">✊</label>
                        <label for="paper-rock">✊</label>
                        <label for="scissors-rock">✊</label>
                      </div>
                      <div
                        onClick={() => setPlayerChoice(1)}
                        className={`${playerChoice == 1 ? "selecteditem" : ""}`}
                      >
                        <label for="rock-paper">🖐️</label>
                        <label for="paper-paper">🖐️</label>
                        <label for="scissors-paper">🖐️</label>
                      </div>
                      <div
                        onClick={() => setPlayerChoice(2)}
                        className={`${playerChoice == 2 ? "selecteditem" : ""}`}
                      >
                        <label for="rock-scissors">✌</label>
                        <label for="paper-scissors">✌</label>
                        <label for="scissors-scissors">✌</label>
                      </div>
                    </div>
                    <div class="blink mt-3">
                      <span className="totaltext">
                        Choose Any One ✊ 🖐️ ✌{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <input
                  className="Playbutton button-89 m-3"
                  type="button"
                  value={playAgain ? "Play again" : "Play"}
                  onClick={(e) => {
                    if (e.target.value == "Play again") {
                      setPlayAgain(false);
                      setPlayerChoice(null);
                      setChoices({
                        contractChoice: null,
                        userChoice: null,
                      });
                    } else playGame();
                  }}
                />
                <div>
                  <Backsound />
                </div>

                <div className="row justify-content-center ">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="game-card">
                      <div className=" p-2">
                        <div
                          className="adjustblock"
                          style={{ justifyContent: "space-around" }}
                        >
                          <div className="rangebox">
                            <div
                              className="bardsdiv1 doubleleft"
                              onClick={() => setBetPtr(0)}
                            >
                              <p>min</p>
                            </div>
                            <div
                              className="bardsdiv1  m-auto"
                              onClick={DecrementBet}
                            >
                              <img
                                src="./react/assets/img/20.png"
                                alt=""
                                className="minrange"
                              />
                            </div>

                            <div className="d-block text-center m-auto">
                              <span className="totalspan text-truncate">Total Bet Amt</span>
                              <div className="bardsdiv1 inputrectangle">
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="12"
                                    value={Bet[BetPtr]}
                                  />
                                </div>
                              </div>
                              <span className="minspan text-truncate">
                                {` MIN ${Bet[0]} MATIC - MAX ${
                                  Bet[Bet.length - 1]
                                } MATIC`}
                              </span>
                            </div>

                            <div
                              className="bardsdiv1  m-auto"
                              onClick={IncrementBet}
                            >
                              <img
                                src="./react/assets/img/19.png"
                                className="minrange"
                                alt=""
                              />
                            </div>

                            <div
                              className="bardsdiv1 doubleright m-auto"
                              onClick={() => setBetPtr(Bet.length - 1)}
                            >
                              <p>max</p>
                            </div>
                          </div>
                          <div className="mobileadjust">
                            <div className="d-block minmax">
                              <span className="balls text-white">Contract</span>
                              <div className="bardsdiv1 ">
                                <a
                                  href={`https://polygonscan.com/address/${process.env.REACT_APP_CONTRACT_ADDRESS}#readContract}`}
                                  target="_blank"
                                >
                                  <img
                                    src="./react/assets/img/18.png"
                                    width={"40px"}
                                    style={{
                                      borderRadius: "4px",
                                      margin: "10px",
                                    }}
                                  ></img>
                                </a>
                              </div>
                            </div>
                            <div className=" minmax tableblock">
                              <span className="balls text-white">Table</span>
                              <div variant="outlined" onClick={handleClickOpen}>
                                <div className="bardsdiv1 ">
                                  <img
                                    src="./react/assets/img/17.png"
                                    width={"30px"}
                                    style={{
                                      borderRadius: "4px",
                                      margin: "10px",
                                    }}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="tablediv ">
                <div className="flexdiv">
                  <div class="balance1">
                    {" "}
                    <span className="balancediv">Balance :</span> {balance}
                  </div>

                  <button onClick={withDrawEarning} className="balance1 mx-2">
                    {" "}
                    {withdrawLoader ? "Withdrawing" : "Withdraw"}{" "}
                  </button>
                </div>
                <table class="table table-striped table-dark resulttable">
                  <thead>
                    <tr>
                      <th scope="col" className="tableheaddiv text-truncate">
                        {" "}
                        Computer choice{" "}
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Player choice
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Result
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Bet Amt
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Profit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyLoader ? (
                      <tr>
                        <td colspan="5" className="border-0">
                          <img
                            src="./react/assets/img/loading.gif"
                            alt="Loading..."
                            style={{ width: "100px" }}
                          ></img>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {history?.length > 0 ? (
                          <>
                            {history?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {" "}
                                    {item.contractsChoice == 0
                                      ? "✊"
                                      : item.contractsChoice == 1
                                      ? "🖐️"
                                      : "✌"}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {item.playerChoice == 0
                                      ? "✊"
                                      : item.playerChoice == 1
                                      ? "🖐️"
                                      : "✌"}
                                  </td>
                                  <td>
                                    {item.result == 0
                                      ? "Loose"
                                      : item.result == 1
                                      ? "Win"
                                      : "Tie"}{" "}
                                  </td>
                                  <td>{item.betAmt} </td>
                                  <td> {item.profit}</td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colspan="5" className="border-0">
                              <img
                                src="./react/assets/img/nofound.gif"
                                alt="Not found..."
                                style={{ width: "100px" }}
                              ></img>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className="p-0 m-0"
          >
            <DialogContent className="p-0 m-0">
              <div className="tablediv1 m-2">
                <div className="flexdiv">
                  <div class="balance1">
                    {" "}
                    <span className="balancediv">Balance :</span> {balance}
                  </div>

                  <button onClick={withDrawEarning} className="balance1">
                    {" "}
                    {withdrawLoader ? "Withdrawing" : "Withdraw"}{" "}
                  </button>
                </div>
                <table class="table table-striped table-dark resulttable">
                  <thead>
                    <tr>
                      <th scope="col" className="tableheaddiv text-truncate">
                        {" "}
                        Computer choice{" "}
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Player choice
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Result
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Bet Amt
                      </th>
                      <th scope="col" className="tableheaddiv text-truncate">
                        Profit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyLoader ? (
                      <tr>
                        <td colspan="5" className="border-0">
                          <img
                            src="./react/assets/img/loading.gif"
                            alt="Loading..."
                            style={{ width: "100px" }}
                          ></img>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {history?.length > 0 ? (
                          <>
                            {history?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {" "}
                                    {item.contractsChoice == 0
                                      ? "✊"
                                      : item.contractsChoice == 1
                                      ? "🖐️"
                                      : "✌"}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {item.playerChoice == 0
                                      ? "✊"
                                      : item.playerChoice == 1
                                      ? "🖐️"
                                      : "✌"}
                                  </td>
                                  <td>
                                    {item.result == 0
                                      ? "Loose"
                                      : item.result == 1
                                      ? "Win"
                                      : "Tie"}{" "}
                                  </td>
                                  <td>{item.result} </td>
                                  <td> {item.profit}</td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colspan="5" className="border-0">
                              <img
                                src="./react/assets/img/nofound.gif"
                                alt="Not found..."
                                style={{ width: "100px" }}
                              ></img>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className="container"></div>
      </section>
    </>
  );
}

export default Rockpaper;
