import { ethers } from "ethers";
import {
  useAccount,
  useContract,
  useNetwork,
  useProvider,
  useSigner,
} from "wagmi";
const abi = require("../abis/abi.json");
const threeroulette = require("../abis/threeroulette.json")
const rockPaperABI = require("../abis/rockPaperABI.json");
const diceGameABI = require("../abis/diceGameABI.json");
function useContractUtilServices() {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_NETWORK_RPC
  );

  const { data: signer } = useSigner({
    onError(error) {
      throw error;
    },
  });

  const WriteContract = useContract({
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: abi,
    signerOrProvider: signer,
  });

  const ReadContract = useContract({
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: abi,
    signerOrProvider: provider,
  });

  // rockpaper

  const RockPaperWriteContract = useContract({
    address: process.env.REACT_APP_ROCKPAPER_CONTRACT_ADDRESS,
    abi: rockPaperABI,
    signerOrProvider: signer,
  });

  const RockPaperReadContract = useContract({
    address: process.env.REACT_APP_ROCKPAPER_CONTRACT_ADDRESS,
    abi: rockPaperABI,
    signerOrProvider: provider,
  });

// threeroulettes
  const ThreeRouletteWriteContract = useContract({
    address: process.env.REACT_APP_THREE_ROULETTE_CONTRACT_ADDRESS,
    abi: threeroulette,
    signerOrProvider: signer,
  });

  const ThreeRouletteReadContract = useContract({
    address: process.env.REACT_APP_THREE_ROULETTE_CONTRACT_ADDRESS,
    abi: threeroulette,
    signerOrProvider: provider,
  });



  // dice game 
  const DiceGameWriteContract = useContract({
    address: process.env.REACT_APP_DICE_GAME_CONTRACT_ADDRESS,
    abi: diceGameABI,
    signerOrProvider: signer,
  });

  const DiceGameReadContract = useContract({
    address: process.env.REACT_APP_DICE_GAME_CONTRACT_ADDRESS,
    abi: diceGameABI,
    signerOrProvider: provider,
  });

  const isWalletConnected = () => {
    if (!address) {
      throw {reason:"Your wallet is not connected!"}
    } else if (chain.id != process.env.REACT_APP_NETWORK_ID) {
      throw new Error("Wrong network detected");
    }
    return true;
  };

  const getWriteContract = () => {
    return WriteContract;
  };

  const getReadContract = () => {
    return ReadContract;
  };

  const getWalletAddress = () => {
    return address;
  };

  const getRockPaperWriteContract = () => {
    return RockPaperWriteContract;
  };

  const getRockPaperReadContract = () => {
    return RockPaperReadContract;
  };
  
  const getDiceGameWriteContract = () => {
    return DiceGameWriteContract;
  };

  const getDiceGameReadContract = () => {
    return DiceGameReadContract;
  };
  const getThreeRouletteWriteContract = () => {
    return ThreeRouletteWriteContract;
  };

  const getThreeRouletteReadContract = () => {
    return ThreeRouletteReadContract;
  };

  return {
    isWalletConnected,
    getWriteContract,
    getReadContract,
    getWalletAddress,
    getRockPaperWriteContract,
    getRockPaperReadContract,
    getDiceGameWriteContract,
    getDiceGameReadContract,
    getThreeRouletteWriteContract,
    getThreeRouletteReadContract,
  };
}

export default useContractUtilServices;
