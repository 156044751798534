// import "./App.css";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import Routers from "./routers/Routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'


const { chains, provider } = configureChains(
  [process.env.REACT_APP_NETWORK_ID == 80001 ? polygonMumbai : polygon],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: process.env.REACT_APP_NETWORK_RPC,
      }),
    })
  ]
);

// const provider = new ethers.providers.JsonRpcProvider(
//   process.env.REACT_APP_NETWORK_RPC
// );

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function App() {
  useEffect(() => {
    // window.onunload  = () => {
    //   localStorage.clear();
    // };
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        closeButton={
          <button
            style={{
              width: "30px",
              backgroundColor: "inherit",
              border: "none",
              color: "white",
            }}
          >
            X
          </button>
        }
      />
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          modalSize="compact"
          theme={lightTheme({
            overlayBlur: "small",
          })}
        >
          <Routers />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
