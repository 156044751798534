import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationModal({Open,setOpenModal,setConfirm,id,setCup}) {

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = (state) => {
    setOpenModal(false);
    setConfirm(state)
    if(state == 0){
      setCup(-1)
    }
  };

  return (
    <div>
      <Dialog
        open={Open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Confirmation Modal"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           <div className='p-3' >
           Do you want to open {id} cup
           </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(0)}>Cancel</Button>
          <Button onClick={() => handleClose(1)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
