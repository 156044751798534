import React, { useEffect, useState, useRef } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import useContractService from "../Services/useContractService";
import jazzpiano from "./jazzpiano.mp3";

import ConfirmationModal from "./ConfirmationModal";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import Slide from "@mui/material/Slide";
import { toast } from "react-toastify";

import { useAccount, useProvider } from "wagmi";
import { Errorhandler } from "../Error/ErrorHandler";
import Web3 from "web3";

import { Menu, MenuItem, useProSidebar } from "react-pro-sidebar";

import useContractUtilServices from "../Services/useContractUtilServices";
import useSound from "use-sound";

import Header from "./Header";
import Backsound from "./Backsound";
const abi = require("../abis/abi.json");

function Thimbles() {
  const [open, setOpen] = React.useState(false);
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();
  const Bet = [ 0.25, 0.5, 1, 2, 5, 10];
  const MIN = 0;
  const MAX = Bet.length - 1;
  const PROFIT = 2.8;
  let N = 3;
  const [BetPtr, setBetPtr] = useState(0);
  const [Shuffle, setShuffle] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [HoverGlass, setHoverGlass] = useState(true);
  const [BallPos, setBallPos] = useState(2);
  const { placeBet, Withdraw, getResult, getBetId, getWithdrawBalance } =
    useContractService();
  const { getWalletAddress } = useContractUtilServices();
  const [OpenModal, setOpenModal] = useState(false);
  const [Confirm, setConfirm] = useState(0);
  const [Cup, setCup] = useState(-1);
  const [loader, setLoader] = useState(false);
  const provide = useProvider();
  const [walleAddressList, setWalletAdressList] = useState([]);
  const [withdrawLoader, setwithdrawLoader] = useState(false);
  const [isWin, setisWin] = useState(null);
  const [History, setHistory] = useState([]);
  const [HistoryLoader, setHistoryLoader] = useState(false);
  const [LatestBetId, setLatestBetId] = useState(0);
  const [withdrawBalance, setWithdrawBalance] = useState(0);
  const { address } = useAccount();

  // const [ref] = useRef;

  // useEffect(() => {
  //   if(ref.current == 1){

  //   }
  // })

  // useEffect(() => {
  //   setTimeout(async () => {
  //     setVolume(0.9);
  //   }, 1000);
  // }, []);

  // song.loop = true;
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const HandleResultClose = () => {
    setisWin(null);
    setHoverGlass(true);
  };

  const web3 = new Web3(Web3.givenProvider);
  const myContract = new web3.eth.Contract(
    abi,
    process.env.REACT_APP_CONTRACT_ADDRESS
  );

  const HandleClick = async () => {
    try {
      // play()
      // setVolume(1);
      setisWin(null);
      setBallPos(false);
      setHoverGlass(false);
      setCup(-1);
      if (Shuffle) return;
      setShuffle(true);
      setTimeout(() => (setShuffle(false), setIsPlayed(true)), 2000);
    } catch (error) {
      Errorhandler(error);
    }
  };

  const DecrementCount = () => {
    if (BetPtr === 0) return;
    setBetPtr((old) => old - 1);
  };

  const IncrementCount = () => {
    if (BetPtr === Bet.length - 1) return;
    setBetPtr((old) => old + 1);
  };

  const HandleHoverGlass = async (id) => {
    try {
      if (Shuffle) return;
      if (OpenModal === false && HoverGlass === false && isPlayed)
        setOpenModal(true);
      if (OpenModal === false && isPlayed) setCup(id);
      // if (isPlayed === false) {
      //   toast.error("You need to play game!");
      // }
    } catch (error) {
      setConfirm(false);
      setHoverGlass(false);
      Errorhandler(error);
      console.log(error);
    }
  };

  const HandleGame = async () => {
    try {
      // setLoader("initilized");`
      const result = await placeBet(Cup, Bet[BetPtr], setLoader);
      console.log("result============", result);
      setLoader(false);
      setConfirm(false);
      setIsPlayed(false);
      if (result[2]._hex == result[3]._hex) {
        setisWin(true);
        // toast.success("Congratulation! You win.");
      } else {
        setisWin(false);
        // toast.error("Ohh! You lose.");
      }
      setBallPos(parseInt(result[3]._hex, 16));
      // setTimeout(() => {
      //   setisWin(null);
      //   setHoverGlass(true);
      //   setBallPos(parseInt(result[3]._hex, 16));
      // }, 5000);
    } catch (error) {
      setCup(-1);
      setIsPlayed(false);
      setConfirm(false);
      setLoader(false);
      Errorhandler(error);
      console.log(error);
    }
  };

  const withdrawEarnings = async () => {
    try {
      setwithdrawLoader(true);
      await Withdraw();
      toast.success("Your amount withdraw successfully!");
      setwithdrawLoader(false);
    } catch (error) {
      setwithdrawLoader(false);
      console.log(error);
      Errorhandler(error);
    }
  };

  const getHistory = async (latestBetId) => {
    try {
      setLatestBetId(latestBetId);
      setHistoryLoader(true);
      let data = [];
      let count = 10;
      while (latestBetId > 0 && count > 0) {
        let result = await getResult(latestBetId);
        if (result.resultDeclared) {
          let amount = web3.utils.fromWei(result[0]._hex, "ether");
          let pickedNo = parseInt(result[2]._hex);
          let resultNumber = parseInt(result[3]._hex);
          let isWin = pickedNo === resultNumber ? true : false;
          let profit = web3.utils.fromWei(result.profit._hex, "ether");
          data.push({ amount, pickedNo, resultNumber, isWin, profit });
        }
        latestBetId--;
        count--;
      }
      setHistory(data);
      setHistoryLoader(false);
    } catch (error) {
      setHistoryLoader(false);
      Errorhandler(error);
    }
  };

  const GetWithdrawBalance = async () => {
    try {
      if (address) {
        let response = await getWithdrawBalance(address);
        setWithdrawBalance(response);
      } else {
        setWithdrawBalance(0);
      }
    } catch (error) {
      Errorhandler(error);
    }
  };

  useEffect(() => {
    if (loader === false) {
      (async () => {
        let latestBetId = await getBetId();
        if (latestBetId > 1) {
          await getHistory(latestBetId);
        }
      })();
    }
  }, [loader]);

  useEffect(() => {
    if (Confirm) {
      (async () => await HandleGame())();
    }
  }, [Confirm]);

  // useEffect(() => {
  //   const song = new Audio("/react/assets/img/jazzpiano.mp3");
  //   song.muted = true;
  //   song.play();
  // }, []);

  useEffect(() => {
    if (withdrawLoader == false || loader == false) {
      (async () => await GetWithdrawBalance())();
    }
  }, [address, withdrawBalance, withdrawLoader, loader]);

  return (
    <>
      {/* <button onClick={() =>stop()}>Stop</button> */}
      {OpenModal ? (
        <ConfirmationModal
          Open={true}
          setOpenModal={setOpenModal}
          setConfirm={setConfirm}
          id={Cup}
          setCup={setCup}
          disableBackdropClick
        />
      ) : null}
      {loader ? <div className={loader}></div> : null}
      {isWin === true ? (
        <div className="win" onClick={() => HandleResultClose()}>
          {/* <img src="/react/assets/img/win.gif"></img>
          <div class="congrats">
            <h1 >Congratulations!</h1>
          </div> */}
          {/* <div className="closediv" >
            <button className=" Playbutton button-89"  > Close</button> 
          </div> */}
        </div>
      ) : isWin === false ? (
        <div className="lose" onClick={() => HandleResultClose()}>
          <img src="/react/assets/img/youlose.png"></img>
        </div>
      ) : null}
      <Header />
      <div className="videoTag videobg "></div>

      <div className="container ">
        <div className="row " style={{ marginTop: "7rem" }}>
          <div className="col-md-8  text-center m-2">
            <img
              src="/react/assets/img/12.png"
              alt=""
              style={{ width: "30%" }}
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-12 col-lg-8 ">
            <div className="">
              <div className="card h-500 cardbg">
                <div className="undercardbg">
                  <div
                    className={`game_container ${
                      Shuffle ? "ballrotation" : ""
                    }`}
                    id="game-container"
                  >
                    <div className="positiondiv1">
                      <button
                        className={` thimble-0 ${
                          HoverGlass === true
                            ? "afterwinning"
                            : "sewing_thimble"
                        } ${
                          Cup === 1 && Cup == BallPos
                            ? "winner"
                            : Cup == 1
                            ? "loser"
                            : ""
                        }`}
                        onClick={() => HandleHoverGlass(1)}
                      ></button>
                      {BallPos === 1 ? (
                        <div className="thimble_ball_position-0 "></div>
                      ) : null}
                    </div>
                    <div className="positiondiv2">
                      <button
                        className={` thimble-1 ${
                          HoverGlass === true
                            ? "afterwinning"
                            : "sewing_thimble"
                        } ${
                          Cup === 2 && Cup == BallPos
                            ? "winner"
                            : Cup == 2
                            ? "loser"
                            : ""
                        }`}
                        onClick={() => HandleHoverGlass(2)}
                      ></button>
                      {BallPos === 2 ? (
                        <div className="thimble_ball_position-1 "></div>
                      ) : null}
                    </div>
                    <div className="positiondiv3">
                      <button
                        className={` thimble-2 ${
                          HoverGlass === true
                            ? "afterwinning"
                            : "sewing_thimble"
                        } ${
                          Cup === 3 && Cup == BallPos
                            ? "winner"
                            : Cup == 3
                            ? "loser"
                            : ""
                        }`}
                        onClick={() => HandleHoverGlass(3)}
                      ></button>
                      {BallPos === 3 ? (
                        <div className="thimble_ball_position-2 "></div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pbutton_container">
                <button
                  disabled={loader}
                  className={`Playbutton button-89`}
                  onClick={() => HandleClick()}
                >
                  <i>Play</i>
                </button>
              </div>
            </div>
            <div>
              {/* <img src="/assets/dice-images/32.png" width={"30px"}></img> */}
              <Backsound />
            </div>
            <div className="game-card">
              <div className=" p-2">
                <div
                  className="adjustblock"
                  style={{ justifyContent: "space-around" }}
                >
                  <div className="rangebox">
                    <div
                      className="bardsdiv1 doubleleft"
                      onClick={() => setBetPtr(0)}
                    >
                      <p>min</p>
                    </div>
                    <div className="bardsdiv1  m-auto">
                      <img
                        src="/react/assets/img/20.png"
                        alt=""
                        className="minrange"
                        onClick={() => DecrementCount()}
                      />
                    </div>

                    <div className="d-block text-center m-auto">
                      <span className="totalspan text-truncate">
                        Total Bet Amt
                      </span>
                      <div className="bardsdiv1 inputrectangle">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="12"
                            value={Bet[BetPtr]}
                          />
                        </div>
                      </div>
                      <span className="minspan text-truncate">
                        MIN {Bet[MIN]} MATIC - MAX {Bet[MAX]} MATIC
                      </span>
                    </div>

                    <div className="bardsdiv1  m-auto">
                      <img
                        src="/react/assets/img/19.png"
                        className="minrange"
                        alt=""
                        onClick={() => IncrementCount()}
                      />
                    </div>

                    <div
                      className="bardsdiv1 doubleright m-auto"
                      onClick={() => setBetPtr(Bet.length - 1)}
                    >
                      <p>max</p>
                    </div>
                  </div>
                  <div className="mobileadjust">
                    <div className="d-block minmax">
                      <span className="balls text-white">1 ball</span>
                      <div className="bardsdiv1 circleback">
                        <span className="ballsspanpara">X</span>
                        <p className="ballsspanpara">{PROFIT}</p>
                      </div>
                    </div>
                    <div className="d-block minmax">
                      <span className="balls text-white">Contract</span>
                      <div className="bardsdiv1 ">
                        <a
                          href={`https://polygonscan.com/address/${process.env.REACT_APP_CONTRACT_ADDRESS}#readContract}`}
                          target="_blank"
                        >
                          <img
                            src="./react/assets/img/18.png"
                            width={"40px"}
                            style={{ borderRadius: "4px", margin: "10px" }}
                          ></img>
                        </a>
                      </div>
                    </div>
                    <div className=" minmax tableblock">
                      <span className="balls text-white">Table</span>
                      <div variant="outlined" onClick={handleClickOpen}>
                        <div className="bardsdiv1 ">
                          <img
                            src="./react/assets/img/17.png"
                            width={"30px"}
                            style={{ borderRadius: "4px", margin: "10px" }}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4  ">
            {/* <div className="history-table">
                  
                
                </div> */}
            <div className="Sidecard">
              <div className="sidediv">
                <h5 className="withdrawbalance">
                  Withdrawable Balance: {withdrawBalance}
                </h5>
                <button
                  className="withdrawbutton"
                  disabled={withdrawLoader}
                  onClick={async () => await withdrawEarnings()}
                >
                  <i>{withdrawLoader ? "Withdrawing..." : "Withdraw"}</i>
                </button>
                <div
                  className="card-header text-center text-dark"
                  style={{ background: "transparent" }}
                >
                  <i>
                    <b>history</b>
                  </i>
                </div>
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Game</th>
                      <th scope="col">Bet Amt</th>
                      <th scope="col">Profit </th>
                    </tr>
                  </thead>
                  <tbody>
                    {HistoryLoader === false ? (
                      History.length > 0 ? (
                        <>
                          {History.slice(0, 10).map((item, idx) => (
                            <tr key={idx}>
                              <td className="text-truncate verticleTD">
                                <div className="thimblesaction">
                                  <>
                                    {Array.from(
                                      { length: 3 },
                                      (_, i) => i + 1
                                    ).map((id) => (
                                      <img
                                        key={id}
                                        src={
                                          (isWin && item.pickedNo === id) ||
                                          item.resultNumber === id
                                            ? "/react/assets/img/activethimbles.png"
                                            : item.pickedNo === id
                                            ? "/react/assets/img/selectedthimble.png"
                                            : "/react/assets/img/Thimble.png"
                                        }
                                        style={{ width: "27px" }}
                                      />
                                    ))}
                                  </>
                                </div>
                              </td>
                              <td className="text-truncate verticleTD">
                                <p>{item.amount}</p>
                              </td>
                              <td className="text-truncate verticleTD">
                                <p>{item.profit}</p>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colspan="3" className="border-0">
                            <img
                              src="./react/assets/img/nofound.gif"
                              style={{ width: "100px" }}
                            ></img>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colspan="3" className="border-0">
                          <img
                            src="./react/assets/img/loading.gif"
                            style={{ width: "100px" }}
                          ></img>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className="p-0 m-0"
          >
            <DialogContent className="p-0 m-0">
              <div className="row">
                <div className="col-md-12 col-lg-12  ">
                  <div className="Sidecard1">
                    <h5>Withdrawable Balance: {withdrawBalance}</h5>
                    <button
                      className="withdrawbutton"
                      disabled={withdrawLoader}
                      onClick={async () => await withdrawEarnings()}
                    >
                      <i>{withdrawLoader ? "Withdrawing..." : "Withdraw"}</i>
                    </button>
                    <div
                      className="card-header text-center text-dark"
                      style={{ background: "transparent" }}
                    >
                      <i>
                        <b>history</b>
                      </i>
                    </div>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Game</th>
                          <th scope="col">Bet Amt</th>
                          <th scope="col">Profit </th>
                        </tr>
                      </thead>
                      <tbody>
                        {HistoryLoader === false ? (
                          History.length > 0 ? (
                            <>
                              {History.slice(0, 10).map((item, idx) => (
                                <tr key={idx}>
                                  <td className="text-truncate verticleTD">
                                    <div className="thimblesaction">
                                      <>
                                        {Array.from(
                                          { length: 3 },
                                          (_, i) => i + 1
                                        ).map((id) => (
                                          <img
                                            key={id}
                                            src={
                                              (isWin && item.pickedNo === id) ||
                                              item.resultNumber === id
                                                ? "/react/assets/img/activethimbles.png"
                                                : item.pickedNo === id
                                                ? "/react/assets/img/selectedthimble.png"
                                                : "/react/assets/img/Thimble.png"
                                            }
                                            style={{ width: "27px" }}
                                          />
                                        ))}
                                      </>
                                    </div>
                                  </td>
                                  <td className="text-truncate verticleTD">
                                    <p>{item.amount}</p>
                                  </td>
                                  <td className="text-truncate verticleTD">
                                    <p>{item.profit}</p>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="3" className="border-0">
                                <img
                                  src="./react/assets/img/nofound.gif"
                                  style={{ width: "100px" }}
                                ></img>
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colspan="3" className="border-0">
                              <img
                                src="./react/assets/img/loading.gif"
                                style={{ width: "100px" }}
                              ></img>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className="container"></div>
      </section>
    </>
  );
}
export default Thimbles;
