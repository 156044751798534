import { toast } from "react-toastify";
import useContractUtilServices from "./useContractUtilServices";
import { ethers } from "ethers";

function useThreeRouletteContractServices() {
  const {
    isWalletConnected,
    getThreeRouletteWriteContract,
    getThreeRouletteReadContract,
  } = useContractUtilServices();
  const ThreeRouletteWriteContract = getThreeRouletteWriteContract();
  const ThreeRouletteReadContract = getThreeRouletteReadContract();
  const getBalance = async (address) => {
    try {
      if (isWalletConnected()) {
        let balance = await ThreeRouletteReadContract.balances(address);
        balance = ethers.utils.formatEther(balance);
        return balance;
      }
    } catch (error) {
      throw error;
    }
  };

  const withdrawEarnings = async () => {
    try {
      if (isWalletConnected()) {
        const balance = await ThreeRouletteWriteContract.withdrawEarnings();
        return balance;
      }
    } catch (error) {
      throw error;
    }
  };

  const getBetId = async () => {
    try {
      if (isWalletConnected()) {
        debugger
        let id = await ThreeRouletteReadContract.betId();
        id = parseInt(id._hex, 16);
        return id;
      }
    } catch (error) {
      throw error;
    }
  };
  const getResultHistory = async (betId) => {
    try {
      if (isWalletConnected()) {
        let result = await ThreeRouletteReadContract.bets(betId.toString());
        return result;
      }
    } catch (error) {
      throw error;
    }
  };

  const bets = async (setLoader, betAmt) => {
    try {
      if (isWalletConnected()) {
        let res = await ThreeRouletteWriteContract.bet({
          value: ethers.utils.parseEther(betAmt.toString()),
        });
        setLoader("waiting");
        const receipt = await res.wait();
        const logs = receipt.events;
        const event = logs.filter(
          (log) => log.event && log.event == "BetResolved"
        );
        const args = event[0]?.args;
        console.log("first wheel",args);
        const wh1 = args?.wheel;
        const roulette = args?.rouletteChoice.toNumber();
        const betId = args?.betId.toNumber();
        const result = args?.result.toNumber();
        const profit = parseInt(args?.profit._hex,16)/1e18;
        const isEligibleForNextRound = args?.isEligibleForNextRound;
        setLoader("result");
        return { wh1, result, betId, roulette,profit, isEligibleForNextRound };
      }
    } catch (error) {
      throw error;
    }
  };

  const secondWheel = async (setLoader, betId) => {
    try {
      if (isWalletConnected()) {
        const res = await ThreeRouletteWriteContract.secondRoulette(
          betId.toString()
        );

        setLoader("waiting");
        const receipt = await res.wait();
        const logs = receipt.events;
        const event = logs.filter(
          (log) => log.event && log.event == "BetResolved"
        );
        const args = event[0].args;
        const wh2 = args.wheel;
        const roulette2 = args.rouletteChoice.toNumber();
        const betId2 = args.betId.toNumber();
        const result2 = args.result.toNumber();
        const profit = parseInt(args.profit._hex,16)/1e18;
        // const profit = args.profit.toNumber();
        const isEligibleForNextRound = args.isEligibleForNextRound;
        setLoader("result");
        return { wh2, result2, betId2, roulette2, profit,isEligibleForNextRound };
      }
    } catch (error) {
      throw error;
    }
  };

  const thirdWheel = async (setLoader, betId) => {
    try {
      if (isWalletConnected()) {
        const res = await ThreeRouletteWriteContract.thirdRoulette(
          betId.toString()
        );
        setLoader("waiting");
        const receipt = await res.wait();
        const logs = receipt.events;
        const event = logs.filter(
          (log) => log.event && log.event == "BetResolved"
        );
        const args = event[0].args;
        const wh3 = args.wheel;
        const roulette3 = args.rouletteChoice.toNumber();
        const betId3 = args.betId.toNumber();
        const result3 = args.result.toNumber();
        const profit = parseInt(args.profit._hex,16)/1e18;
        const isEligibleForNextRound = args.isEligibleForNextRound;
        setLoader("result");
        return { wh3, result3, betId3, roulette3,profit, isEligibleForNextRound };
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    getBalance,
    withdrawEarnings,
    bets,
    secondWheel,
    thirdWheel,
    getBetId,
    getResultHistory,
  };
}

export default useThreeRouletteContractServices;
