import React, { useEffect, useState } from "react";

import useDiceGameContractService from "../Services/useDiceGameContractService";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Errorhandler } from "../Error/ErrorHandler";
import Header from "./Header";
import Web3 from "web3";

import { ethers } from "ethers";
import Backsound from "./Backsound";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

function DiceGame() {
  const { address } = useAccount();
  const { getBalance, withdrawEarnings, getBetId, getResultHistory, playGame } =
    useDiceGameContractService();
  const Bet = [0.25, 0.5, 1, 2, 5, 10];
  const MIN = 0;
  const MAX = Bet.length - 1;
  const [BetAmt, setBetAmt] = useState(0);
  const [Count, setCount] = useState(3);
  const [Choice, setChoice] = useState(null); // 0 -> Minor && 1 -> Major
  const [balance, setBalance] = useState(0);
  const [Dice1, setDice1] = useState(null);
  const [Dice2, setDice2] = useState(null);
  const [sumOfDice, setSumOfDice] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isWin, setIsWin] = useState(null);
  const [history, setHistory] = useState(null);
  const [historyLoader, setHistoryLoader] = useState(false);
  const [winAmt, setWinAmt] = useState(null);
  const [isPlayBtnClicked, setIsPlayBtnClicked] = useState(false);
  const [showDice, setShowDice] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [withdrawLoader, setWithdrawLoader] = useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClose = () => {
    console.log("history close ");

    setOpen(false);
  };
  const handleClickOpen = () => {
   
    console.log("history open");
    setOpen(true);
  };
  const Price = {
    3: [34, 1.05], // Minor , Major
    4: [11, 1.15],
    5: [5, 1.3],
    6: [3, 1.5],
    7: [2, 2],
    8: [1.5, 3],
    9: [1.3, 5],
    10: [1.15, 11],
    11: [1.05, 34],
  };

  const IncrementCount = () => {
    if (Count === 11) return;
    setCount(Count + 1);
  };

  const DecrementCount = () => {
    if (Count === 3) return;
    setCount(Count - 1);
  };

  const DecrementBetAmt = () => {
    if (BetAmt == 0) return;
    setBetAmt((old) => old - 1);
  };

  const IncrementBetAmt = () => {
    if (BetAmt == Bet.length - 1) return;
    setBetAmt((old) => old + 1);
  };

  const getBalances = async () => {
    try {
      const result = await getBalance(address);
      setBalance(result);
    } catch (error) {
      throw error;
    }
  };
  const withDrawEarning = async (e) => {
    e.preventDefault();
    try {
      setWithdrawLoader(true);
      const result = await withdrawEarnings();
      setWithdrawLoader(false);
      toast.success("Your amount withdraw successfully!");
    } catch (error) {
      setWithdrawLoader(false);
      toast.error(error);
      throw error;
    }
  };
  const playDiceGame = async () => {
    setIsPlayBtnClicked(true);
    setWinAmt(null);
    let isLess = null;
    try {
      if (Choice === 0) {
        isLess = 1;
      } else if (Choice && Choice === 1) {
        isLess = 0;
      } else {
    setIsPlayBtnClicked(false);
        toast.error("please select your choice")
      };
      if (isLess !== null) {
        setShowDice(true);
        let { dice1, dice2, result, profit, amount } = await playGame(
          Bet[BetAmt],
          Count,
          isLess,
          setLoader
        );
        setIsPlayBtnClicked(false);
        if (profit) {
          setWinAmt(ethers.utils.formatEther(profit));
        }
        if (result == 1) {
          setTimeout(() => {
            setIsWin(true);
          }, 5000);
        } else if (result === 0) {
          setTimeout(() => {
            setIsWin(false);
          }, 5000);
        }

        setLoader(false);
        setDice1(dice1);
        setDice2(dice2);
        setSumOfDice(dice1 + dice2);
        setChoice(null);
        setIsPlayBtnClicked(false);
      }
    } catch (error) {
      setIsPlayBtnClicked(false);
      setLoader(false);
      Errorhandler(error);
      throw error;
    }
  };

  const web3 = new Web3(Web3.givenProvider);

  const resultHistory = async () => {
    try {
      let betId = await getBetId();
      setHistoryLoader(true);
      if (betId) {
        const resultData = [];
        let count = 10;
        while (betId > 0 && count > 0) {
          const res = await getResultHistory(betId);
          let dice1 = res.dice1.toNumber();
          let dice2 = res.dice2.toNumber();
          let result = res.result.toNumber();
          let profit = web3.utils.fromWei(res.profit._hex, "ether");
          let betAmt = web3.utils.fromWei(res.amount._hex, "ether");
          let selectedNumber = res.predeterminedNumber.toNumber();
          let isLess = res.isLess;
          let sum = dice1 + dice2;
          resultData.push({
            dice1,
            dice2,
            result,
            profit,
            betAmt,
            selectedNumber,
            isLess,
            sum,
          });
          betId--;
          count--;
        }
        setHistoryLoader(false);
        setHistory(resultData);
      }
    } catch (error) {
      setHistoryLoader(false);
      throw error;
    }
  };
  const HandleResultClose = () => {
    setIsWin(null);
  };
  useEffect(() => {
    getBalances();
    resultHistory();
  }, [isWin]);

  return (
    <>
      <Header />
      {loader ? <div className={loader}></div> : null}
      {isWin === true ? (
        <div className="win" onClick={() => HandleResultClose()}></div>
      ) : isWin === false ? (
        <div className="lose" onClick={() => HandleResultClose()}>
          <img src="/react/assets/img/youlose.png"></img>
        </div>
      ) : null}
      <section
        className="incassection"
        style={{ paddingBottom: "1rem", paddingTop: "8rem" }}
      >
        <div className="container-fluid dicecontainer ">
          <div className="dice-section" style={{ position: "relative" }}>
            <div className="row upprside">
              <div className="col-md-12">
                <div
                  className="card"
                  style={{
                    background: "rgb(34, 33, 30)",
                    borderRadius: "34px",
                    margin: "10px 0px",
                  }}
                >
                  <div className="uppersidediv">
                    <div className="row justify-content-center">
                      <div className="col-md-2 m-auto text-center">
                        <img
                          src="./react/assets/img/logo.png"
                          alt=""
                          width="82px"
                          className="leftimages my-auto"
                        />
                      </div>
                      <div className="col-md-3 my-2">
                        <div className=" mobilemanage">
                          <div className="higherside">
                            <h5
                              className={`max-para1 text-truncate ${
                                Choice == 1 ? "choiceselected" : ""
                              }`}
                              onClick={() => setChoice(1)}
                            >
                              HIGHER
                            </h5>
                          </div>
                          <div className="lowerside">
                            <h5
                              className={`max-para1 text-truncate ${
                                Choice == 0 ? "choiceselected" : ""
                              }`}
                              onClick={() => setChoice(0)}
                            >
                              LESSER
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex mx-auto  rangesetter">
                        <div className="numberdiv">
                          <h5 className="numberdivnumber">{Count}</h5>
                        </div>
                        <div
                          className="d-grid"
                          style={{ margin: "auto 10px", gap: 8 }}
                        >
                          <img
                            src="./react/assets/dice-images/23.png"
                            alt=""
                            width="26px"
                            onClick={() => IncrementCount()}
                            className="upperimage"
                          />
                          <img
                            src="./react/assets/dice-images/24.png"
                            alt=""
                            width="26px"
                            onClick={() => DecrementCount()}
                            className="upperimage"
                          />
                        </div>
                      </div>
                      <div className="col-md-5 m-auto">
                        <div className="paysection">
                          <div className="d-flex payslip">
                            <h5>PAY</h5>
                            <h5>X {Price[Count][Choice]}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-around" }}
                    />
                  </div>
                </div>
                <div className="uppersidedi1">
                  <h5>
                    {isWin
                      ? `YOU WON ${winAmt} MATIC`
                      : isWin == 0
                      ? `YOU LOOSE ${Bet[BetAmt]} MATIC`
                      : ""}
                  </h5>
                </div>
              </div>
            </div>
            <div className="row gamerow">
              <div className="col-md-6" style={{ position: "relative" }}>
                <div className="rightside">
                  <div className="diceanimation1"></div>
                </div>
              </div>
              <div className="col-md-6" style={{ position: "relative" }}>
                <div className="leftside">
                  {showDice ? (
                    <div className="diceanimation">
                      <input
                        class="setA rollA1"
                        type="radio"
                        name="die"
                        id="rollA1"
                        checked={Dice1 == 2 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setB rollB1"
                        type="radio"
                        name="die"
                        id="rollB1"
                        checked={Dice1 == 3 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setA rollA2"
                        type="radio"
                        name="die"
                        id="rollA2"
                        checked={Dice1 == 5 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setB rollB2"
                        type="radio"
                        name="die"
                        id="rollB2"
                        checked={Dice1 == 3 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setA rollA3"
                        type="radio"
                        name="die"
                        id="rollA3"
                        checked={Dice1 == 6 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setB rollB3"
                        type="radio"
                        name="die"
                        id="rollB3"
                        checked={Dice1 == 1 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setA rollA4"
                        type="radio"
                        name="die"
                        id="rollA4"
                        checked={Dice1 == 6 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setB rollB4"
                        type="radio"
                        name="die"
                        id="rollB4"
                        checked={Dice1 == 6 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setA rollA5"
                        type="radio"
                        name="die"
                        id="rollA5"
                        checked={Dice1 == 4 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setB rollB5"
                        type="radio"
                        name="die"
                        id="rollB5"
                        checked={Dice1 == 6 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setA rollA6"
                        type="radio"
                        name="die"
                        id="rollA6"
                        checked={Dice1 == 2 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setB rollB6"
                        type="radio"
                        name="die"
                        id="rollB6"
                        checked={Dice1 == 4 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setA rollA7"
                        type="radio"
                        name="die"
                        id="rollA7"
                        checked={Dice1 == 4 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setB rollB7"
                        type="radio"
                        name="die"
                        id="rollB7"
                        checked={Dice1 == 1 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setA rollA8"
                        type="radio"
                        name="die"
                        id="rollA8"
                        checked={Dice1 == 3 && Dice2 == 12 ? true : false}
                      />
                      <input
                        class="setB rollB8"
                        type="radio"
                        name="die"
                        id="rollB8"
                        checked={Dice1 == 3 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setA rollA9"
                        type="radio"
                        name="die"
                        id="rollA9"
                        checked={Dice1 == 5 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setB rollB9"
                        type="radio"
                        name="die"
                        id="rollB9"
                        checked={Dice1 == 1 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setA rollA10"
                        type="radio"
                        name="die"
                        id="rollA10"
                        checked={Dice1 == 3 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setB rollB10"
                        type="radio"
                        name="die"
                        id="rollB10"
                        checked={Dice1 == 6 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setA rollA11"
                        type="radio"
                        name="die"
                        id="rollA11"
                        checked={Dice1 == 1 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setB rollB11"
                        type="radio"
                        name="die"
                        id="rollB11"
                        checked={Dice1 == 4 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setA rollA12"
                        type="radio"
                        name="die"
                        id="rollA12"
                        checked={Dice1 == 6 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setB rollB12"
                        type="radio"
                        name="die"
                        id="rollB12"
                        checked={Dice1 == 2 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setA rollA13"
                        type="radio"
                        name="die"
                        id="rollA13"
                        checked={Dice1 == 6 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setB rollB13"
                        type="radio"
                        name="die"
                        id="rollB13"
                        checked={Dice1 == 6 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setA rollA14"
                        type="radio"
                        name="die"
                        id="rollA14"
                        checked={Dice1 == 3 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setB rollB14"
                        type="radio"
                        name="die"
                        id="rollB14"
                        checked={Dice1 == 4 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setA rollA15"
                        type="radio"
                        name="die"
                        id="rollA15"
                        checked={Dice1 == 5 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setB rollB15"
                        type="radio"
                        name="die"
                        id="rollB15"
                        checked={Dice1 == 5 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setA rollA16"
                        type="radio"
                        name="die"
                        id="rollA16"
                        checked={Dice1 == 1 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setB rollB16"
                        type="radio"
                        name="die"
                        id="rollB16"
                        checked={Dice1 == 1 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setA rollA17"
                        type="radio"
                        name="die"
                        id="rollA17"
                        checked={Dice1 == 1 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setB rollB17"
                        type="radio"
                        name="die"
                        id="rollB17"
                        checked={Dice1 == 2 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setA rollA18"
                        type="radio"
                        name="die"
                        id="rollA18"
                        checked={Dice1 == 4 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setB rollB18"
                        type="radio"
                        name="die"
                        id="rollB18"
                        checked={Dice1 == 4 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setA rollA19"
                        type="radio"
                        name="die"
                        id="rollA19"
                        checked={Dice1 == 5 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setB rollB19"
                        type="radio"
                        name="die"
                        id="rollB19"
                        checked={Dice1 == 1 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setA rollA20"
                        type="radio"
                        name="die"
                        id="rollA20"
                        checked={Dice1 == 6 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setB rollB20"
                        type="radio"
                        name="die"
                        id="rollB20"
                        checked={Dice1 == 4 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setA rollA21"
                        type="radio"
                        name="die"
                        id="rollA21"
                        checked={Dice1 == 3 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setB rollB21"
                        type="radio"
                        name="die"
                        id="rollB21"
                        checked={Dice1 == 5 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setA rollA22"
                        type="radio"
                        name="die"
                        id="rollA22"
                        checked={Dice1 == 1 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setB rollB22"
                        type="radio"
                        name="die"
                        id="rollB22"
                        checked={Dice1 == 3 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setA rollA23"
                        type="radio"
                        name="die"
                        id="rollA23"
                        checked={Dice1 == 4 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setB rollB23"
                        type="radio"
                        name="die"
                        id="rollB23"
                        checked={Dice1 == 6 && Dice2 == 5 ? true : false}
                      />
                      <input
                        checked={Dice1 == 3 && Dice2 == 4 ? true : false}
                        class="setA rollA24"
                        type="radio"
                        name="die"
                        id="rollA24"
                      />
                      <input
                        class="setB rollB24"
                        type="radio"
                        name="die"
                        id="rollB24"
                        checked={Dice1 == 5 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setA rollA25"
                        type="radio"
                        name="die"
                        id="rollA25"
                        checked={Dice1 == 1 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setB rollB25"
                        type="radio"
                        name="die"
                        id="rollB25"
                        checked={Dice1 == 2 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setA rollA26"
                        type="radio"
                        name="die"
                        id="rollA26"
                        checked={Dice1 == 4 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setB rollB26"
                        type="radio"
                        name="die"
                        id="rollB26"
                        checked={Dice1 == 2 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setA rollA27"
                        type="radio"
                        name="die"
                        id="rollA27"
                        checked={Dice1 == 1 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setB rollB27"
                        type="radio"
                        name="die"
                        id="rollB27"
                        checked={Dice1 == 4 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setA rollA28"
                        type="radio"
                        name="die"
                        id="rollA28"
                        checked={Dice1 == 5 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setB rollB28"
                        type="radio"
                        name="die"
                        id="rollB28"
                        checked={Dice1 == 2 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setA rollA29"
                        type="radio"
                        name="die"
                        id="rollA29"
                        checked={Dice1 == 4 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setB rollB29"
                        type="radio"
                        name="die"
                        id="rollB29"
                        checked={Dice1 == 5 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setA rollA30"
                        type="radio"
                        name="die"
                        id="rollA30"
                        checked={Dice1 == 5 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setB rollB30"
                        type="radio"
                        name="die"
                        id="rollB30"
                        checked={Dice1 == 5 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setA rollA31"
                        type="radio"
                        name="die"
                        id="rollA31"
                        checked={Dice1 == 2 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setB rollB31"
                        type="radio"
                        name="die"
                        id="rollB31"
                        checked={Dice1 == 2 && Dice2 == 2 ? true : false}
                      />
                      <input
                        class="setA rollA32"
                        type="radio"
                        name="die"
                        id="rollA32"
                        checked={Dice1 == 2 && Dice2 == 1 ? true : false}
                      />
                      <input
                        class="setB rollB32"
                        type="radio"
                        name="die"
                        id="rollB32"
                        checked={Dice1 == 6 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setA rollA33"
                        type="radio"
                        name="die"
                        id="rollA33"
                        checked={Dice1 == 2 && Dice2 == 4 ? true : false}
                      />
                      <input
                        class="setB rollB33"
                        type="radio"
                        name="die"
                        id="rollB33"
                        checked={Dice1 == 3 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setA rollA34"
                        type="radio"
                        name="die"
                        id="rollA34"
                        checked={Dice1 == 3 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setB rollB34"
                        type="radio"
                        name="die"
                        id="rollB34"
                        checked={Dice1 == 1 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setA rollA35"
                        type="radio"
                        name="die"
                        id="rollA35"
                        checked={Dice1 == 2 && Dice2 == 3 ? true : false}
                      />
                      <input
                        class="setB rollB35"
                        type="radio"
                        name="die"
                        id="rollB35"
                        checked={Dice1 == 5 && Dice2 == 5 ? true : false}
                      />
                      <input
                        class="setA rollA36"
                        type="radio"
                        name="die"
                        id="rollA36"
                        checked={Dice1 == 6 && Dice2 == 6 ? true : false}
                      />
                      <input
                        class="setB rollB36"
                        type="radio"
                        name="die"
                        id="rollB36"
                        checked={Dice1 == 3 && Dice2 == 4 ? true : false}
                      />
                      <div class="result">
                        <div class="result-bg-wrapper result-bg-wrapperA">
                          <div class="result-bg result-bgA"></div>
                        </div>
                        <div class="result-bg-wrapper result-bg-wrapperB">
                          <div class="result-bg result-bgB"></div>
                        </div>
                        <div class="result-content">
                          <div class="result-A2">
                            <span>2</span>
                          </div>
                          <div class="result-B2">
                            <span>2</span>
                          </div>
                          <div class="result-A3">
                            <span>3</span>
                          </div>
                          <div class="result-B3">
                            <span>3</span>
                          </div>
                          <div class="result-A4">
                            <span>4</span>
                          </div>
                          <div class="result-B4">
                            <span>4</span>
                          </div>
                          <div class="result-A5">
                            <span>5</span>
                          </div>
                          <div class="result-B5">
                            <span>5</span>
                          </div>
                          <div class="result-A6">
                            <span>6</span>
                          </div>
                          <div class="result-B6">
                            <span>6</span>
                          </div>
                          <div class="result-A7">
                            <span>7</span>
                          </div>
                          <div class="result-B7">
                            <span>7</span>
                          </div>
                          <div class="result-A8">
                            <span>8</span>
                          </div>
                          <div class="result-B8">
                            <span>8</span>
                          </div>
                          <div class="result-A9">
                            <span>9</span>
                          </div>
                          <div class="result-B9">
                            <span>9</span>
                          </div>
                          <div class="result-A10">
                            <span>10</span>
                          </div>
                          <div class="result-B10">
                            <span>10</span>
                          </div>
                          <div class="result-A11">
                            <span>11</span>
                          </div>
                          <div class="result-B11">
                            <span>11</span>
                          </div>
                          <div class="result-A12">
                            <span>12</span>
                          </div>
                          <div class="result-B12">
                            <span>12</span>
                          </div>
                        </div>
                      </div>
                      <div class="dice">
                        <div class="die-wrapper die1">
                          <div class="die">
                            <div class="die-inner">
                              <div class="face face1">
                                <span class="pips-md"></span>
                              </div>
                              <div class="face face2">
                                <span class="pips-tl"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face3">
                                <span class="pips-tl"></span>
                                <span class="pips-md"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face4">
                                <span class="pips-tl"></span>
                                <span class="pips-tr"></span>
                                <span class="pips-bl"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face5">
                                <span class="pips-tl"></span>
                                <span class="pips-tr"></span>
                                <span class="pips-md"></span>
                                <span class="pips-bl"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face6">
                                <span class="pips-tl"></span>
                                <span class="pips-tr"></span>
                                <span class="pips-mdl"></span>
                                <span class="pips-mdr"></span>
                                <span class="pips-bl"></span>
                                <span class="pips-br"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="die-wrapper die2">
                          <div class="die">
                            <div class="die-inner">
                              <div class="face face1">
                                <span class="pips-md"></span>
                              </div>
                              <div class="face face2">
                                <span class="pips-tl"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face3">
                                <span class="pips-tl"></span>
                                <span class="pips-md"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face4">
                                <span class="pips-tl"></span>
                                <span class="pips-tr"></span>
                                <span class="pips-bl"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face5">
                                <span class="pips-tl"></span>
                                <span class="pips-tr"></span>
                                <span class="pips-md"></span>
                                <span class="pips-bl"></span>
                                <span class="pips-br"></span>
                              </div>
                              <div class="face face6">
                                <span class="pips-tl"></span>
                                <span class="pips-tr"></span>
                                <span class="pips-mdl"></span>
                                <span class="pips-mdr"></span>
                                <span class="pips-bl"></span>
                                <span class="pips-br"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="diceanimation text-center">
                      <img
                        src="/react/assets/img/rollingdice.gif"
                        style={{ width: "50%", margin:"64px" }}
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="upprside1">
              <div className="row">
                <div className="col-md-8">
                  <div className="gridrow" >
                    <div className="undergrid">
                      <div className="helf">
                        <h5 className="text-center text-white max-para">
                          HELP
                        </h5>
                      </div>
                      <div className="sound max-para">
                        <Backsound />
                      </div>
                    </div>
                    <div className=" maticmobile" >
                      <div className="maximum">
                        <p className="max-para text-truncate">
                          MAXIMUM BET : {Bet[MAX]} MATIC
                        </p>
                      </div>
                      <div className="balance">
                        <p className="max-para">BALANCE : {balance} MATIC</p>
                      </div>
                    </div>
                  </div>
                  <div className="lowergrid">
                    <div className="matic-amount">
                      <div className="row m-auto ">
                        <div className="col-md-3">
                          <div className="rangedecide">
                            <div className="minimage">
                              <h5
                                className="max-para"
                                onClick={() => setBetAmt(MIN)}
                              >
                                MIN
                              </h5>
                            </div>
                            <img
                              src="./react/assets/dice-images/24.png"
                              alt=""
                              width="26px"
                              className="my-auto mx-2"
                              onClick={() => DecrementBetAmt()}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="max-para text-truncate">
                            BET AMOUNT MATIC : {Bet[BetAmt]}
                          </p>
                        </div>
                        <div className="col-md-3 text-end">
                          <div className="rangedecide rightsidepara ">
                            <img
                              src="./react/assets/dice-images/23.png"
                              alt=""
                              width="26px"
                              className="my-auto mx-2"
                              onClick={() => IncrementBetAmt()}
                            />
                            <div className="minimage">
                              <h5
                                className="max-para"
                                onClick={() => setBetAmt(MAX)}
                              >
                                Max
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-4 m-auto"
                  style={{ padding: " 19px 19px 0px;" }}
                >
                  <div
                    className="d-flex flexhistory"
                    
                  >
                    <div className="history" onClick={handleClickOpen}>
                      <p className="max-para">HISTORY</p>
                    </div>
                    <div className="helf">
                      <a
                        href={`https://mumbai.polygonscan.com/address/${process.env.REACT_APP_DICE_GAME_CONTRACT_ADDRESS}#readContract}`}
                        target="_blank"
                      >
                        <p className="max-para">SMART CONTRACT</p>
                      </a>
                    </div>
                  </div>
                  <div className=" playbuttondiv ">
                    {isPlayBtnClicked ? (
                      <button
                        disabled={isPlayBtnClicked ? true : false}
                        className="tryagain"
                      >
                        {" "}
                        playing{" "}
                      </button>
                    ) : (
                      <img
                        src="./react/assets/dice-images/29.png"
                        alt=""
                        style={{ width: "100%" }}
                        onClick={() => {
                          playDiceGame();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="tablesections">
          <div className="container-fluid" style={{ width: "90%" }}>
            <div className="flexdiv justify-content-center mt-3">
              <div class="balance1">
                {" "}
                <span className="balancediv">Balance :</span>
                {balance}
              </div>
              <button onClick={withDrawEarning} className="balance1 mx-2">
                {" "}
                {withdrawLoader ? "Withdrawing" : "Withdraw"}{" "}
              </button>
            </div>
            <div>
              <table class="table table-striped table-dark Dicetable">
                <thead>
                  <tr>
                    <th scope="col" className="text-truncate">
                      Dice 1{" "}
                    </th>
                    <th scope="col" className="text-truncate">
                      Dice 2
                    </th>
                    <th scope="col" className="text-truncate">
                      Sum of dice
                    </th>
                    <th scope="col" className="text-truncate">
                      Predetermined Number
                    </th>
                    <th scope="col" className="text-truncate">
                      Major/Minor
                    </th>
                    <th scope="col" className="text-truncate">
                      Result
                    </th>
                    <th scope="col" className="text-truncate">
                      Bet Amount
                    </th>
                    <th scope="col" className="text-truncate">
                      Profit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {historyLoader ? (
                    <tr>
                      <td colspan="8" className="border-0">
                        <img
                          src="./react/assets/img/loading.gif"
                          alt="Loading..."
                          style={{ width: "100px" }}
                        ></img>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {history?.length > 0 ? (
                        <>
                          {history?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.dice1} </td>
                                <td>{item.dice2} </td>
                                <td> {item.sum}</td>
                                <td>{item.selectedNumber} </td>
                                <td> {item.isLess ? "Minor" : "Major"} </td>
                                <td> {item.result ? "Win" : "Loose"} </td>
                                <td> {item.betAmt} </td>
                                <td> {item.profit} </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colspan="8" className="border-0">
                            <img
                              src="./react/assets/img/nofound.gif"
                              alt="Not found..."
                              style={{ width: "100px" }}
                            ></img>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section> */}
      </section>
      <section>
        {
          open && <div>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Sound
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  save
                </Button>
              </Toolbar>
            </AppBar>
            <List>
              <div className="container-fluid" style={{ width: "90%" }}>
                <div className="flexdiv justify-content-center mt-3">
                  <div class="balance1">
                    {" "}
                    <span className="balancediv">Balance :</span>
                    {balance}
                  </div>
                  <button onClick={withDrawEarning} className="balance1 mx-2">
                    {" "}
                    {withdrawLoader ? "Withdrawing" : "Withdraw"}{" "}
                  </button>
                </div>
                <div>
                  <table class="table table-striped table-dark Dicetable">
                    <thead>
                      <tr>
                        <th scope="col" className="text-truncate">
                          Dice 1{" "}
                        </th>
                        <th scope="col" className="text-truncate">
                          Dice 2
                        </th>
                        <th scope="col" className="text-truncate">
                          Sum of dice
                        </th>
                        <th scope="col" className="text-truncate">
                          Predetermined Number
                        </th>
                        <th scope="col" className="text-truncate">
                          Major/Minor
                        </th>
                        <th scope="col" className="text-truncate">
                          Result
                        </th>
                        <th scope="col" className="text-truncate">
                          Bet Amount
                        </th>
                        <th scope="col" className="text-truncate">
                          Profit
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyLoader ? (
                        <tr>
                          <td colspan="8" className="border-0">
                            <img
                              src="./react/assets/img/loading.gif"
                              alt="Loading..."
                              style={{ width: "100px" }}
                            ></img>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {history?.length > 0 ? (
                            <>
                              {history?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.dice1} </td>
                                    <td>{item.dice2} </td>
                                    <td> {item.sum}</td>
                                    <td>{item.selectedNumber} </td>
                                    <td> {item.isLess ? "Minor" : "Major"} </td>
                                    <td> {item.result ? "Win" : "Loose"} </td>
                                    <td> {item.betAmt} </td>
                                    <td> {item.profit} </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <tr>
                              <td colspan="8" className="border-0">
                                <img
                                  src="./react/assets/img/nofound.gif"
                                  alt="Not found..."
                                  style={{ width: "100px" }}
                                ></img>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </List>
          </Dialog>
        </div>
        }
      </section>
    </>
  );
}

export default DiceGame;
