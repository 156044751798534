import { toast } from "react-toastify";

export const Errorhandler = (e) => {
  
  if(e.code && e.code == -32603){
    return toast.error("Insufficient balance!")
  }
  return toast.error(
    e?.reason ? e?.reason : e.error?.message ? e.error?.message : e?.message,
    { autoClose: 6000 }
  );
};
