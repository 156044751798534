import { ethers } from "ethers";
import useContractUtilServices from "./useContractUtilServices";
import { toast } from "react-toastify";

function useContractService() {
  const {
    isWalletConnected,
    getWriteContract,
    getReadContract,
    getWalletAddress,
    getRockPaperWriteContract,
    getRockPaperReadContract,
  } = useContractUtilServices();
  const WriteContract = getWriteContract();
  const ReadContract = getReadContract();
  const rockPaperWriteContract = getRockPaperWriteContract();
  const placeBet = async (no, amount, setLoader) => {
    try {
      if (isWalletConnected()) {
        const resp = await WriteContract.placeBet(no, {
          value: ethers.utils.parseEther(amount.toString()),
        });
        setLoader("waiting");
        const receipt = await resp.wait();
        const logs = receipt.events;
        const event = logs.filter(
          (log) => log.event && log.event == "BetPlaced"
        );
        const args = event[0].args;
        const betId = parseInt(args[1]._hex, 16);
        let result;
        setLoader("result1");
        let isTimeout;
        setTimeout(() => {
          isTimeout = true;
        }, 120000); // 2 minutes in milliseconds

        do {
          if (isTimeout) {
            throw new Error(
              "Take to long to get result.Check after some time..."
            );
          }
          result = await WriteContract.bets(betId);
        } while (parseInt(result[3]._hex, 16) == 0);
        // clearTimeout(timeout);
        return result;
      } 
    } catch (error) {
      throw error;
    }
  };

  const Withdraw = async (amount) => {
    try {
      if (isWalletConnected()) {
        let balance = await WriteContract.balances(getWalletAddress());
        if (balance > 0) {
          const response = await WriteContract.withdrawEarnings();
          await response.wait();
        } else {
          throw new Error("Withdraw not available!");
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const getResult = async (betId) => {
    try {
      let result = await ReadContract.bets(betId.toString());
      return result;
    } catch (error) {
      throw error;
    }
  };

  const getBetId = async () => {
    try {
      let latestBetId = await ReadContract.betId();
      latestBetId = parseInt(latestBetId._hex, 16);
      return latestBetId;
    } catch (error) {
      throw error;
    }
  };

  const getWithdrawBalance = async (address) => {
    try {
      let balance = await ReadContract.balances(address);
      balance = ethers.utils.formatEther(balance);
      return balance;
    } catch (error) {
      throw error;
    }
  };

  // rockpaper

  const playRockPaper = async (betAmt, choice ,setLoader) => {
    try {
      if (isWalletConnected()) {
        const res = await rockPaperWriteContract.play(choice, {
          value: ethers.utils.parseEther(betAmt.toString()),
        });
        setLoader("waiting");
        const receipt = await res.wait();
        const logs = receipt.events;
        const event = logs.filter(
          (log) => log.event && log.event == "BetResolved"
        );
        const args = event[0].args;
        let contractChoice = event[0].args.contractChoice;
        let userChoice = event[0].args.playerChoice;
        setLoader("result");
        const result = parseInt(args.result._hex, 16);
        return {result , contractChoice , userChoice};
      }
    } catch (error) {
      throw error;
    }
  };
  const getMaxMinBet = async () => {
    try {
      if (isWalletConnected) {
        const maxBet = await getRockPaperReadContract().maxBet();
        const minBet = await getRockPaperReadContract().minBet();
        return { maxBet, minBet };
      }
    } catch (error) {
      throw error;
    }
  };
  const getBalance = async (address) => {
    try {
      if (isWalletConnected()) {
        let balance = await getRockPaperReadContract().balances(address);
        balance  =  ethers.utils.formatEther(balance);
        return balance
      }
    } catch (error) {
      throw error;
    }
  };

  const withdrawEarnings = async () => {
    try {
      if (isWalletConnected()) {
        const balance = await getRockPaperWriteContract().withdrawEarnings();
        return balance;
      }
    } catch (error) {
      throw error;
    }
  };

  const betId = async () => {
    try {
      if (isWalletConnected()) {
        let id = await getRockPaperReadContract().betId();
        id = parseInt(id._hex, 16);
        return id;
      }
    } catch (error) {
      throw error;
    }
  };

  const getResultHistory = async(betId)=>{
    try {
      let result = await getRockPaperReadContract().bets(betId.toString());
      return result;
    } catch (error) {
      throw error;
    }
  }
  return {
    placeBet,
    getResult,
    Withdraw,
    getBetId,
    getWithdrawBalance,
    playRockPaper,
    getMaxMinBet,
    getBalance,
    withdrawEarnings,
    betId,
    getResultHistory
  };
}

export default useContractService;
