import useContractUtilServices from "./useContractUtilServices";
import { ethers } from "ethers";

function useDiceGameContractService() {
  const {
    isWalletConnected,
    getDiceGameWriteContract,
    getDiceGameReadContract,
  } = useContractUtilServices();

  const getBalance = async (address) => {
    try {
      if (isWalletConnected()) {
        let balance = await getDiceGameReadContract().balances(address);
        balance = ethers.utils.formatEther(balance);
        return balance;
      }
    } catch (error) {
      throw error;
    }
  };

  const withdrawEarnings = async () => {
    try {
      if (isWalletConnected()) {
        const balance = await getDiceGameWriteContract().withdrawEarnings();
        return balance;
      }
    } catch (error) {
      throw error;
    }
  };

  const getBetId = async () => {
    try {
      if (isWalletConnected()) {
        let id = await getDiceGameReadContract().betId();
        id = parseInt(id._hex, 16);
        return id;
      }
    } catch (error) {
      throw error;
    }
  };
  const getResultHistory = async (betId) => {
    try {
      if (isWalletConnected()) {
        let result = await getDiceGameReadContract().bets(betId.toString());
        return result;
      }
    } catch (error) {
      throw error;
    }
  };

  const playGame = async (betAmt, selectedNum, isLess, setLoader) => {
    try {
      if (isWalletConnected()) {
        let res = await getDiceGameWriteContract().playGame(
          selectedNum,
          isLess,
          {
            value: ethers.utils.parseEther(betAmt.toString()),
          }
        );
        setLoader("waiting");
        const receipt = await res.wait();
        const logs = receipt.events;
        const event = logs.filter(
          (log) => log.event && log.event == "BetResolved"
        );
        const args = event[0].args;
        const dice1 = args.dice1.toNumber();
        const dice2 = args.dice2.toNumber();
        const profit = args.profit.toNumber();
        const result = args.result.toNumber();
        const amount = args.amount.toNumber();
        setLoader("result");
        return { dice1, dice2, result, profit, amount };
      }
    } catch (error) {
      throw error;
    }
  };

  return { getBalance, withdrawEarnings, getBetId, getResultHistory, playGame };
}

export default useDiceGameContractService;
