import React, { useEffect, useState } from "react";
import jazzpiano from "./jazzpiano.mp3";
import useSound from "use-sound";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";



function Backsound() {
    const [Volume, setVolume] = useState(1);
    const [value, setValue] = React.useState(100);
    const [isPlayBtnClicked , setIsPlayBtnClicked] = useState(false);
    const handleChange = (event, newValue) => {
      console.log("newValue",newValue);
      setValue(newValue);
      newValue ? setVolume(newValue / 100) : setVolume(0);
    };
    const [play, { stop }] = useSound(jazzpiano, {
      autoplay: true,
      volume: Volume,
      interrupt: true,
    });
  return (
    <>
      <Box sx={{ width: 200 }}>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <VolumeDown />
          <Slider aria-label="Volume" value={value} onChange={handleChange} />
          <VolumeUp />
        </Stack>
      </Box>
    </>
  );
}

export default Backsound;
