import React, { useState } from "react";
import {
  RiHome4Line,
  RiTeamLine,
  RiCalendar2Line,
  RiFolder2Line,
  RiUserFollowLine,
  RiPlantLine,
  RiStackLine,
  RiUserUnfollowLine,
} from "react-icons/ri";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
function Sidebars() {
  //const { collapseSidebar } = useProSidebar();
  // const [collapsed, setCollapsed] = useState(false);
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();

  // const [toggled, setToggled] = useState(false);

  // const handleCollapsedChange = () => {
  //   setCollapsed(!collapsed);
  // };
  // const handleToggleSidebar = (value) => {
  //   setToggled(value);
  // };

  return (
    <div>
      <Sidebar
        className={`app ${toggled ? "toggled" : ""}`}
        style={{ height: "100%", position: "absolute" }}
        closeOnClick={collapseSidebar}
        open={!collapsed}
        defaultCollapsed={true}
        // collapsed={collapsed}
        // toggled={toggled}
        // handleToggleSidebar={toggleSidebar}
        // handleCollapsedChange={collapseSidebar}
      >
        <main>
          {/* {console.log(collapsed)} */}
          <Menu>
            {collapsed ? (
              <MenuItem onClick={() => collapseSidebar(false)}>
               
               <span className="closehandle" >X</span>
              </MenuItem>
            ) : (
              <MenuItem onClick={collapseSidebar}>
               <span className="closehandle">X</span>
              </MenuItem>
            )}
          </Menu>

          <Menu>
            <MenuItem icon={<RiHome4Line />}>Ranking</MenuItem>
            <MenuItem icon={<RiHome4Line />}>Wallet Balance</MenuItem>
            <MenuItem icon={<RiHome4Line />}>Dashboard</MenuItem>
            <MenuItem icon={<RiHome4Line />}>Dashboard</MenuItem>
          </Menu>
        </main>
      </Sidebar>
    </div>
  );
}
export default Sidebars;
