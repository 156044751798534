import React, { useEffect, useState } from "react";
import { RouletteWheel } from "react-casino-roulette";
import StyleComp from "../Component/StyleComp";
import "react-casino-roulette/dist/index.css";
import Header from "./Header";
import Backsound from "./Backsound";
import useThreeRouletteContractServices from "../Services/useThreeRouletteContractServices";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import Web3 from "web3";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Errorhandler } from "../Error/ErrorHandler";

export const ThreeRoulette = () => {
  const {
    bets,
    secondWheel,
    thirdWheel,
    getBalance,
    withdrawEarnings,
    getBetId,
    getResultHistory,
  } = useThreeRouletteContractServices();
  const [start, setStart] = useState(false);
  const { address } = useAccount();
  const [balance, setBalance] = useState(0);
  const [winningBet, setWinningBet] = useState("-1");
  const [loader, setLoader] = useState(false);
  const [withdrawLoader, setWithdrawLoader] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(false);
  const [history, setHistory] = useState([]);
  const [isWin, setisWin] = useState(null);
  const [winAmt, setWinAmt] = useState(0);
  const [active, setActive] = useState({
    firstWheel: false,
    secWheel: false,
    thirdWheel: false,
    shadoweffect: false,
  });
  const [spinDisable, setSpinDisable] = useState(false);
  const Bet = [0.25, 0.5, 1, 2, 5, 10, 20, 50, 100];
  const MIN = 0;
  const MAX = Bet.length - 1;
  const [BetAmt, setBetAmt] = useState(0);
  const target = 150;
  const doSpin = () => {
    setWinningBet("00");
    setStart(true);
  };

  const DecrementBetAmt = () => {
    if (BetAmt == 0) return;
    setBetAmt((old) => old - 1);
  };

  const IncrementBetAmt = () => {
    if (BetAmt == Bet.length - 1) return;
    setBetAmt((old) => old + 1);
  };

  const getBalances = async () => {
    try {
      const result = await getBalance(address);
      setBalance(result);
    } catch (error) {
      throw error;
    }
  };

  const withDrawEarning = async (e) => {
    e.preventDefault();
    try {
      setWithdrawLoader(true);
      const result = await withdrawEarnings();
      setWithdrawLoader(false);
      toast.success("Your amount withdraw successfully!");
    } catch (error) {
      setWithdrawLoader(false);
      toast.error(error.reason);
      throw error;
    }
  };

  const web3 = new Web3(Web3.givenProvider);
  const resultHistory = async () => {
    try {
      let id = await getBetId();
      setHistoryLoader(true);
      if (id) {
        const resultData = [];
        let count = 10;
        while (id > 0 && count > 0) {
          const res = await getResultHistory(id);
          console.log("history", res);
          let firstWheelResult = res.firstWheelResult.toNumber();
          let SecondWheelResult = res.SecondWheelResult.toNumber();
          let ThirdWheelResult = res.ThirdWheelResult.toNumber();
          let profit = web3.utils.fromWei(res.profit._hex, "ether");
          let betAmt = web3.utils.fromWei(res.amount._hex, "ether");
          let rouletteOption = res.rouletteOption.toNumber();
          let betId = id;
          let user = res.user;
          resultData.push({
            firstWheelResult,
            SecondWheelResult,
            ThirdWheelResult,
            profit,
            rouletteOption,
            betId,
            user,
            betAmt,
          });
          id--;
          count--;
        }
        setHistoryLoader(false);
        setHistory(resultData);
      }
    } catch (error) {
      setHistoryLoader(false);
      throw error;
    }
  };

  let wheel = document.querySelector(".wheel");
  let wheel1 = document.querySelector(".wheel1");
  let wheel2 = document.querySelector(".wheel2");
  // let spinBtn1 = document.querySelector(".spinBtn1");
  // let spinBtn = document.querySelector(".spinBtn");

  // let wheel1value = Math.ceil(Math.random() * 3600);

  const spinner = async () => {
    setWinAmt(0);
    setSpinDisable(true);
    try {
      setLoader(true);
      const { wh1, result, betId, roulette, profit, isEligibleForNextRound } =
        await bets(setLoader, Bet[BetAmt]);
      setWinAmt(profit);
      setSpinDisable(false);
      setActive((prevState) => ({
        ...prevState,
        secWheel: true,
        thirdWheel: true,
        shadoweffect: true,
      }));

      setTimeout(() => {
        if (result == 1) {
          setisWin("win");
        } else if (isEligibleForNextRound) {
          setisWin(null);
        } else setisWin("loose");
      }, 5500);
      setLoader(false);
      if (parseInt(roulette) == 1) {
        wheel.style.transform = "rotate(" + 3464 + "deg)";
      } else if (parseInt(roulette) == 2) {
        wheel.style.transform = "rotate(" + 5323 + "deg)";
      } else if (parseInt(roulette) == 3) {
        wheel.style.transform = "rotate(" + 3400 + "deg)";
      } else if (roulette == 4) {
        wheel.style.transform = "rotate(" + 106 + "deg)";
      } else if (roulette == 5) {
        wheel.style.transform = "rotate(" + 5386 + "deg)";
        try {
          setLoader(true);

          const {
            wh2,
            result2,
            betId2,
            roulette2,
            profit,
            isEligibleForNextRound,
          } = await secondWheel(setLoader, betId);
          setWinAmt(profit);
          setActive((prevState) => ({
            ...prevState,
            thirdWheel: true,
            secWheel: false,
            shadoweffect: true,
          }));
          setLoader(false);
          setTimeout(() => {
            if (result2 == 1) {
              setisWin("win");
            } else if (isEligibleForNextRound) {
              setisWin(null);
            } else setisWin("loose");
          }, 5500);
          if (roulette2 == 1) {
            wheel1.style.transform = "rotate(" + 7919 + "deg)";
          } else if (roulette2 == 2) {
            wheel1.style.transform = "rotate(" + 7875 + "deg)";
          } else if (roulette2 == 3) {
            wheel1.style.transform = "rotate(" + 7964 + "deg)";
          } else if (roulette2 == 4) {
            wheel1.style.transform = "rotate(" + 7830 + "deg)";
          } else if (roulette2 == 5) {
            wheel1.style.transform = "rotate(" + 8009 + "deg)";

            try {
              const {
                wh3,
                result3,
                betId3,
                profit,
                roulette3,
                isEligibleForNextRound,
              } = await thirdWheel(setLoader, betId);
              setWinAmt(profit);
              setActive((prevState) => ({
                ...prevState,
                thirdWheel: false,
                shadoweffect: true,
              }));
              setTimeout(() => {
                if (result3 == 1) {
                  setisWin("win");
                } else if (isEligibleForNextRound) {
                  setisWin(null);
                } else setisWin("loose");
              }, 5500);
              if (roulette3 == 1) {
                wheel2.style.transform = "rotate(" + 1935 + "deg)";
              } else if (roulette3 == 2) {
                wheel2.style.transform = "rotate(" + 1888 + "deg)";
              } else if (roulette3 == 3) {
                wheel2.style.transform = "rotate(" + 1865 + "deg)";
              } else if (roulette3 == 4) {
                wheel2.style.transform = "rotate(" + 1956 + "deg)";
              } else if (roulette3 == 5) {
                wheel2.style.transform = "rotate(" + 2093 + "deg)";
              } else if (roulette3 == 6) {
                wheel2.style.transform = "rotate(" + 1844 + "deg)";
              }
            } catch (error) {
              setLoader(false);
              toast.error(error.reason);
              setActive((prevState) => ({
                ...prevState,
                shadoweffect: false,
              }));
            }
          }
        } catch (error) {
          setLoader(false);
          toast.error(error.reason);
          setActive((prevState) => ({
            ...prevState,
            shadoweffect: false,
          }));
        }
      }
    } catch (error) {
      setSpinDisable(false);
      setLoader(false);
      Errorhandler(error);
      setActive((prevState) => ({
        ...prevState,
        shadoweffect: false,
      }));
      // toast.error(error.reason);
    }
  };
  const HandleResultClose = () => {
    setisWin(null);
  };

  useEffect(() => {
    getBalances();
    resultHistory();
  }, [isWin, withdrawLoader]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [open, setOpen] = React.useState(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <>
      {loader ? <div className={loader}> </div> : ""}

      {isWin == "win" ? (
        <div
          className="threereouletteswin "
          onClick={() => HandleResultClose()}
        >
          <h1 className="winingprice">Your Wining Price</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <h1 className="threereouletteswindiv"> {winAmt ? winAmt : ""}</h1>
          <div className="tryagainbutton">
            <button
              className="spinbutton1 m-auto "
              onClick={() => HandleResultClose()}
            >
              Try Again
            </button>
          </div>
        </div>
      ) : isWin == "loose" ? (
        <div className="lose" onClick={() => HandleResultClose()}>
          <img src="/react/assets/img/youlose.png"></img>
        </div>
      ) : (
        ""
      )}
      <Header />
      <div id="roulettes">
        <section>
          <div className="rouettes">
            <div className="spinBtn" onClick={spinner}>
              <button
                className="spindesign"
                disabled={spinDisable ? true : false}
              >
                {" "}
                spin
              </button>
            </div>

            <div
              className={`wheel ${active.firstWheel ? "active" : ""} ${
                active.shadoweffect ? "shadoweffect" : ""
              }`}
            >
              <div className="number part1">
                <span>Next</span>
              </div>
              <div className="number part2">
                <span>X 0.5</span>
              </div>
              <div className="number part3">
                <span>0</span>
              </div>
              <div className="number part4">
                <span>X 1.5</span>
              </div>
              <div className="number part5">
                <span>X 1</span>
              </div>
              <div className="number part6">
                <span>0</span>
              </div>
            </div>
            <div
              className={`wheel1 ${active.secWheel ? "active" : ""} ${
                active.shadoweffect ? "shadoweffect" : ""
              }`}
            >
              <div className="number part7">
                <span>Next</span>
              </div>
              <div className="number part8">
                <span>X2</span>
              </div>
              <div className="number part9">
                <span>0</span>
              </div>
              <div className="number part10">
                <span>X1.5</span>
              </div>
              <div className="number part11">
                <span>X5</span>
              </div>
              <div className="number part12">
                <span>X2</span>
              </div>
              <div className="number part13">
                <span>0</span>
              </div>
              <div className="number part14">
                <span>X1.5</span>
              </div>
            </div>
            <div
              className={`wheel2 ${active.thirdWheel ? "active" : ""} ${
                active.shadoweffect ? "shadoweffect" : ""
              }`}
            >
              <div className="number part15 ">
                <span>X3</span>
              </div>
              <div className="number part16">
                <span>X35</span>
              </div>
              <div className="number part17">
                <span>X2</span>
              </div>
              <div className="number part18">
                <span>X3</span>
              </div>
              <div className="number part19">
                <span>X2</span>
              </div>
              <div className="number part20">
                <span>0</span>
              </div>
              <div className="number part21">
                <span>X10</span>
              </div>
              <div className="number part22">
                <span>X2</span>
              </div>
              <div className="number part23">
                <span>X3</span>
              </div>
              <div className="number part24">
                <span>0</span>
              </div>
              <div className="number part25">
                <span>X2</span>
              </div>
              <div className="number part26">
                <span>X3</span>
              </div>
              <div className="number part27">
                <span>X5</span>
              </div>
              <div className="number part28">
                <span>0</span>
              </div>
              <div className="number part29">
                <span>X3</span>
              </div>

              <div className="number part30">
                <span>X2</span>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="container ">
            <div className=" rangedecider justify-content-center">
              <div className="my-2">
                <Backsound />
              </div>
              <div>
                <div className="maxbuttondiv2">
                  <div className="spinBtn" onClick={spinner}>
                    <button
                      className="spinbutton1 "
                      disabled={spinDisable ? true : false}
                    >
                      Spin
                    </button>
                  </div>
                </div>
              </div>
              <div className="underrange">
                <div>
                  <div className="maxbuttondiv2">
                    <a
                      href={`https://mumbai.polygonscan.com/address/0xB67b42cB5C36b0ABbe5342a333A7f960027C0805#contracts`}
                      target="_blank"
                    >
                      <div className="spinBtn">
                        <button className=" helpbutton m-auto">
                          {" "}
                          <img src="react/assets/threeroulettes/contract.png"></img>{" "}
                        </button>
                        <p className="belowmimages">Smart Contract</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="maxbuttondiv2">
                    <div className="spinBtn">
                      <button className=" helpbutton">
                        {" "}
                        <img src="react/assets/threeroulettes/help.png"></img>{" "}
                      </button>
                      <p className="belowmimages">Help</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="maxbuttondiv2">
                    <div className="spinBtn" onClick={resultHistory}>
                      <button className=" helpbutton" onClick={handleClickOpen}>
                        {" "}
                        <img src="react/assets/threeroulettes/history.png"></img>{" "}
                      </button>
                      <p className="belowmimages">History</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flexdiv  mt-4" style={{ justifyContent: "center" }}>
              <div className="balance1">
                {" "}
                <span className="balancediv">Balance :</span> {balance}
              </div>

              <button onClick={withDrawEarning} className="balance1 mx-2">
                {" "}
                {withdrawLoader ? "Withdrawing" : "Withdraw"}{" "}
              </button>
            </div>
            <div className="row justify-content-center my-4">
              <div className="col-3 col-sm-3 col-md-3 col-lg-1 col-xl-1 my-auto mx-0">
                <div className="maxbuttondiv1">
                  <button className="minbutton" onClick={() => setBetAmt(MIN)}>
                    min
                  </button>
                </div>
              </div>
              <div className="col-6 col-sm-6  col-md-6 col-lg-3 col-xl-3 p-0 my-auto mx-0">
                <div className="text-center text-white">
                  <div className="betcolumn">
                    <div className="classflex">
                      <div
                        className="minmaxadjust"
                        onClick={() => DecrementBetAmt()}
                      >
                        -
                      </div>
                      <div className="betamountcolumn">
                        <p className="m-0 yourbet">YOUR BET</p>
                        <h3 className="betheader text-truncate  ">
                          Matic {Bet[BetAmt]}
                        </h3>
                      </div>
                      <div
                        className="minmaxadjust"
                        onClick={() => IncrementBetAmt()}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 col-sm-3 col-md-3 col-lg-1 col-xl-1 my-auto mx-0">
                <div className="maxbuttondiv">
                  <button className="minbutton" onClick={() => setBetAmt(MAX)}>
                    max
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section>
        {open && (
          <div>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <List>
                <table className="table table-striped table-dark Dicetable">
                  <thead>
                    <tr>
                      <th className="text-truncate">Bet Id</th>
                      {/* <th className="text-truncate"> 1st Wheel Result</th>
                <th className="text-truncate">2nd Wheel Result</th>
                <th className="text-truncate">3rd Wheel Result</th>
                <th className="text-truncate">Roulette Option</th> */}
                      <th className="text-truncate"> Bet Amount </th>
                      <th className="text-truncate">profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyLoader ? (
                      <tr>
                        <td colspan="7" className="border-0">
                          <img
                            src="./react/assets/img/loading.gif"
                            alt="Loading..."
                            style={{ width: "100px" }}
                          ></img>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {history?.length > 0 ? (
                          <>
                            {history?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.betId}</td>
                                  {/* <td>{item.firstWheelResult}</td>
                            <td>{item.SecondWheelResult}</td>
                            <td>{item.ThirdWheelResult} </td>
                            <td> {item.rouletteOption}</td> */}
                                  <td> {item.betAmt}</td>
                                  <td> {item.profit}</td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colspan="5" className="border-0">
                              <img
                                src="./react/assets/img/nofound.gif"
                                alt="Not found..."
                                style={{ width: "100px" }}
                              ></img>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </List>
            </Dialog>
          </div>
        )}
      </section>
    </>
  );
};

export default ThreeRoulette;
