import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "react-router-dom";




function Header() {
  return (
   <header className="comman-header py-4"  >
    <div className="container" >
    <div className="row ">
      <div className="col-md-6 col-6 m-auto">
        <Link to="gaming-club.app/" >
        <img src="/react/assets/img/logo.png" alt="" style={{ width: "150px" }} />
        </Link>
       
      </div>
      <div className="col-md-6 col-6">
        <div className="d-flex" style={{ justifyContent: "end" }}>
          <a className="sc-fzoXzr fUiryO connectwallet mt-2">
            <ConnectButton
              showBalance={false}
              chainStatus="icon"
              accountStatus="address"
            />
          </a>
        </div>
      </div>
    </div>
    </div>
   </header>
  );
}

export default Header;
